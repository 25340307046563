import React, { Card, CardHeader } from '@mui/material';
import WalletIcon from '@mui/icons-material/Wallet';
import SelectList from '../SelectList/SelectList';

export default function WalletCard() {
    return (
        <Card
            raised
            sx={[
                {
                    width: '370px',
                    height: 197,
                    background: 'rgba(23, 125, 255, 1)',
                    borderRadius: 2,
                },
                {
                    '@media (max-width: 375px)': {
                        width: '90vw', // Меняем ширину на 90% при ширине экрана 375px и меньше
                    },
                },
            ]}
        >
            <CardHeader
                sx={{
                    color: 'primary.contrastText',
                    '& .MuiCardHeader-action': { alignSelf: 'center' }, // Центрирование action button
                }}
                avatar={<WalletIcon sx={{ color: 'primary.contrastText' }} />}
                title="Wallet"
                titleTypographyProps={{
                    sx: { fontSize: { xs: 18, md: 20 }, fontWeight: 'bold' }, // Адаптивный размер текста
                }}
                action={<SelectList />}
            />
        </Card>
    );
}
