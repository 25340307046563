import React, { useEffect } from 'react';
import { Box, Button, Container, Paper } from '@mui/material';
import Grid from '@mui/material/Grid2';
import WalletCard from './components/WalletCard/WalletCard.tsx';
import Header from './components/Header/Header.tsx';
import Footer from './components/Footer/Footer.tsx';
import TelegramAuth from './components/TelegramAuth/TelegramAuth.tsx';
import { AuthProvider } from './context/AuthContext/AuthContext';
import { Route, Router, Routes, Link } from 'react-router-dom';
import PrivateRoute from './route/PrivateRoute/PrivateRoute';
import MainPage from './pages/MainPage/MainPage';
import WalletPage from './pages/WalletPage/WalletPage';
import TradeBotPage from './pages/TradeBotPage/TradeBotPage';
import StatsPage from './pages/StatsPage/StatsPage';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import NotificationsPage from './pages/NotificationsPage/NotificationsPage';
import SettingsPage from './pages/SettingsPage/SettingsPage';

function App() {
    const [isVerified, setIsVerified] = React.useState('');
    useEffect(() => {
        const initTelegram = () => {
            const telegramWebApp = window.Telegram.WebApp;
            telegramWebApp.ready();
            telegramWebApp.expand(); // Разворачивает приложение на весь экран
        };
        initTelegram();
    }, []);

    const handleVerification = () => {
        const initData =
            'query_id=AAFQdwgaAAAAAFB3CBrLNI3l&user=%7B%22id%22%3A436762448%2C%22first_name%22%3A%22Sergey%22%2C%22last_name%22%3A%22Phyodorov%22%2C%22username%22%3A%22Phyodorov%22%2C%22language_code%22%3A%22ru%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1730737450&hash=89fcaaed8d38e0c3edbdc02543f5cff0cae2c439dc3007a1a6bb01bcdd2157c1';
        fetch('http://31.128.36.216:80/verify', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ telegramInitData: initData }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.verified) {
                    setIsVerified('User data verified');
                    console.log(
                        `User data verified: ${data.verified}`,
                        data.user
                    );
                } else {
                    setIsVerified(`Verification failed ${data.verified}`);
                    console.log('Verification failed');
                }
            });
    };

    return (
        <Container
            component="main"
            sx={{ position: 'relative', paddingX: { xs: 0, sm: '1rem' } }}
        >
            <Paper
                elevation={0}
                sx={{ marginX: 'auto', boxShadow: 0, borderRadius: 0 }}
            >
                <Header />
                <Box>
                    <Button onClick={handleVerification}>Verify</Button>
                    <p>{isVerified}</p>
                </Box>
                <Grid
                    container
                    direction="column"
                    spacing={2}
                    alignItems="center"
                    sx={{ paddingBottom: '16px' }}
                >
                    <Grid>
                        <TelegramAuth />
                    </Grid>
                    <Grid>
                        <Box component="section">
                            <p>info wallet</p>
                            <WalletCard />
                        </Box>
                    </Grid>
                    <Grid>
                        <section>
                            <p>Recommended</p>
                            <WalletCard />
                        </section>
                    </Grid>
                    <Grid>
                        <section>
                            <p>My Assets</p>
                            <WalletCard />
                        </section>
                    </Grid>
                </Grid>
                <Routes>
                    <Route path="/" element={<MainPage />} />
                    <Route path="/wallet" element={<WalletPage />} />
                    <Route path="/tradeBot" element={<TradeBotPage />} />
                    <Route path="/stats" element={<StatsPage />} />
                    <Route
                        path="notifications"
                        element={<NotificationsPage />}
                    />
                    <Route path="/settings" element={<SettingsPage />}>
                        {/*TODO: Сделать вложенные роуты (Outlet)*/}
                        <Route path="profile" element={<ProfilePage />} />
                        <Route path="security" element={<p>Безопасность</p>} />
                    </Route>
                </Routes>
                <Footer />
            </Paper>
        </Container>
    );
}

export default App;
