import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {useState} from "react";

export default function SelectList() {
    const [coin, setCoin] = useState('');

    const handleChange = (event: SelectChangeEvent) => {
        setCoin(event.target.value);
    };

    return (
            <FormControl sx={{ m: 1, minWidth: 80, }} size='small'>
                <InputLabel id="demo-simple-select-autowidth-label">Coin</InputLabel>
                <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={coin}
                    onChange={handleChange}
                    autoWidth
                    label="Coin"
                >
                    <MenuItem value={20}>USD</MenuItem>
                    <MenuItem value={21}>SDLX</MenuItem>
                    <MenuItem value={22}>BTN</MenuItem>
                </Select>
            </FormControl>
    );
}
