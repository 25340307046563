import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import WalletIcon from '@mui/icons-material/Wallet';
import InsightsIcon from '@mui/icons-material/Insights';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { Link } from 'react-router-dom';

export default function Footer() {
    const [value, setValue] = React.useState('recents');

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <BottomNavigation
            sx={{
                width: '100%',
                position: 'sticky',
                bottom: 0,
                right: 0,
                left: 'auto',
            }}
            value={value}
            onChange={handleChange}
        >
            <BottomNavigationAction
                label="Main"
                value="main"
                icon={<HomeIcon />}
                component={Link}
                to="/"
            />
            <BottomNavigationAction
                label="Wallet"
                value="walet"
                icon={<WalletIcon />}
                component={Link}
                to="/wallet"
            />
            <BottomNavigationAction
                label="Trade Bot"
                value="tradeBot"
                icon={<InsightsIcon />}
                component={Link}
                to="/tradeBot"
            />
            <BottomNavigationAction
                label="Stats"
                value="stats"
                icon={<QueryStatsIcon />}
                component={Link}
                to="/stats"
            />
        </BottomNavigation>
    );
}
