import { AppBar, Toolbar, Box, Avatar, IconButton, Badge } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import React from 'react';
import { Link } from 'react-router-dom';

export default function Header() {
    return (
        <AppBar sx={{ boxShadow: 0 }} position="sticky" color="inherit">
            <Toolbar
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    px: 2,
                }}
            >
                {/* Аватар слева */}
                <Link to="/settings/profile">
                    <Avatar
                        src="https://via.placeholder.com/40"
                        alt="User Avatar"
                        sx={{ width: 48, height: 48 }}
                    />
                </Link>
                {/* Иконки справа */}
                <Box display="flex" alignItems="center" gap={2}>
                    <IconButton component={Link} to="/settings" color="inherit">
                        <SettingsIcon />
                    </IconButton>
                    <IconButton
                        component={Link}
                        to="/notifications"
                        color="inherit"
                    >
                        <Badge badgeContent={4} color="error">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                </Box>
            </Toolbar>
        </AppBar>
    );
}
