import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';

export default function TelegramAuth() {
    const [user, setUser] = useState(null);
    const [initDataUnsafe, setInitDataUnsafe] = useState(null);
    const [initData, setInitData] = useState(null);
    const [isVerified, setIsVerified] = useState<string | null>(null);

    useEffect(() => {
        const telegramWebApp = window.Telegram.WebApp; // Получаем объект Telegram
        const TelegramInitData = window.Telegram.WebApp.initData; // Получаем данные от Telegram

        // Обновление данных пользователя из Телеграм
        if (telegramWebApp.isExpanded) {
            const initDataUnsafe = telegramWebApp.initDataUnsafe;
            setUser(initDataUnsafe.user);
            setInitDataUnsafe(initDataUnsafe);
            setInitData(TelegramInitData);
        } else {
            setUser(null);
            setInitDataUnsafe(null);
            setInitData(null);
        }
        telegramWebApp.ready();
        return () => {
            telegramWebApp.close();
        };
    }, []);

    const handleVerification = () => {
        if (initData) {
            fetch('http://31.128.36.216:80/verify', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ telegramInitData: initData }),
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.verified) {
                        setIsVerified('User data verified');
                        console.log(
                            `User data verified: ${data.verified}`,
                            data.user
                        );
                    } else {
                        setIsVerified(`Verification failed ${data.verified}`);
                        console.log('Verification failed');
                    }
                });
        }
    };

    return (
        <Box
            width={200}
            style={{
                wordWrap: 'break-word',
                whiteSpace: 'pre-wrap',
                overflowWrap: 'break-word',
            }}
        >
            {user ? (
                <div>
                    <h2>Добро пожаловать!</h2>

                    <p>Ваше user:</p>
                    {Object.entries(user).map(([key, value]) => (
                        <p key={key} style={{ wordWrap: 'break-word' }}>
                            {key}: {JSON.stringify(value, null, 2)}
                        </p>
                    ))}
                    <br />
                    <p>Ваше initDataUnsafe:</p>
                    {Object.entries(initDataUnsafe).map(([key, value]) => (
                        <p key={key} style={{ wordWrap: 'break-word' }}>
                            {key}: {JSON.stringify(value, null, 2)}
                        </p>
                    ))}
                    <br />
                    <p style={{ wordWrap: 'break-word' }}>
                        Ваше initData: {JSON.stringify(initData, null, 2)}
                    </p>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleVerification}
                    >
                        Verify
                    </Button>
                    <p
                        style={{
                            wordWrap: 'break-word',
                            color: isVerified ? 'green' : 'red',
                        }}
                    >
                        Верификация {isVerified}
                    </p>
                </div>
            ) : (
                <h2>Пожалуйста, выполните авторизацию через Telegram.</h2>
            )}
        </Box>
    );
}
