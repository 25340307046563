import React from 'react';

export default function NotificationsPage() {
    return (
        <div>
            <h1>Notifications Page</h1>
            <p>This is the notifications page</p>
        </div>
    );
}
