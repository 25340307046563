import React from 'react';

export default function ProfilePage() {
    return (
        <div>
            <h1>Profile Page</h1>
            <p>This is the profile page</p>
        </div>
    );
}
