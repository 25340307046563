import React from 'react';

export default function SettingsPage() {
    return (
        <div>
            <h1>Settings Page</h1>
            <p>This is the settings page</p>
        </div>
    );
}
