import React from 'react';

export default function StatsPage() {
    return (
        <div>
            <h1>Stats Page</h1>
            <p>This is the stats page</p>
        </div>
    );
}
