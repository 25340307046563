import React from 'react';

export default function WalletPage() {
    return (
        <div>
            <h1>Wallet Page</h1>
            <p>This is the wallet page</p>
        </div>
    );
}
