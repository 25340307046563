import React from 'react';

export default function TradeBotPage() {
    return (
        <div>
            <h1>Trade Bot Page</h1>
            <p>This is the trade bot page</p>
        </div>
    );
}
